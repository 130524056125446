* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: #EDF2F9;
}

#root, html, body {
  width: 100%;
  height: 100%;
}
h1, h2, h3, h4, h5, h6, ul, li {
  margin: 0;
  padding: 0;
}

/* Font Sizes */
h2 {
  font-size: 2.375rem;
}

h4 {
  font-size: 1.375rem
}
p {
  font-size: 1rem;
}

@media screen and (max-width: 667px) {
  h2 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.2rem;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #09d3ac;
}
